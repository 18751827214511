<template>
  <b-container fluid v-if="$root.$data.me.PortalAdmin">
    <b-row class="mb-4">
      <b-col>
        <h1>Page Snippet<template v-if="snippet"> - {{ snippet.Name }}</template></h1>
      </b-col>
    </b-row>

    <b-row class="mt-3" v-if="snippet">
      <b-col cols="12">

        <h3 class="mt-3">Edit custom snippet</h3>
        <ckeditor :editor="editor" v-model="snippet.Custom" />

        <b-button class="mt-3" variant="primary" @click.prevent="save">Save</b-button>
        <b-button class="ml-3 mt-3" :to="{ name: 'pagesnippets' }">Back without saving</b-button>

        <h3 class="mt-3">Default for reference:</h3>
        <div v-html="snippet.Default" style="border: 1px solid black; padding: 10px;"></div>

      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    name: 'PageSnippets',
    components: {
      'ckeditor': CKEditor.component,
    },
    data () {
      return {
        editor: ClassicEditor,
        editorData: '',
        loading: true,
        saving: false,
        snippet: null,
      }
    },
    props: {
      id: String,
    },
    methods: {
      save () {
        this.saving = true

        this.$http
          .put('page-snippets-combined/' + this.id, this.snippet)
          .then(response => {
            this.snippet = response.data
            this.$notify({
              type: 'success',
              text: 'Snippet saved'
            })
            this.saving = false
          }).catch(() => {
            this.saving = false
            this.$notify({
              type: 'error',
              text: 'An error occurred while saving'
            })
          })
      },
    },
    mounted () {
      this.loading = true
      this.$http
        .get('page-snippets-combined/' + this.id)
        .then(response => {
            this.loading = false
            this.snippet = response.data
            if (this.snippet.Custom == null)
              this.snippet.Custom = this.snippet.Default
        }).catch(function () {
            this.loading = false
        })
    }
  }
</script>

<style scoped>
</style>