var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.me.PortalAdmin
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", [
                _c(
                  "h1",
                  [
                    _vm._v("Page Snippet"),
                    _vm.snippet
                      ? [_vm._v(" - " + _vm._s(_vm.snippet.Name))]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _vm.snippet
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h3", { staticClass: "mt-3" }, [
                        _vm._v("Edit custom snippet")
                      ]),
                      _c("ckeditor", {
                        attrs: { editor: _vm.editor },
                        model: {
                          value: _vm.snippet.Custom,
                          callback: function($$v) {
                            _vm.$set(_vm.snippet, "Custom", $$v)
                          },
                          expression: "snippet.Custom"
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.save($event)
                            }
                          }
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-3 mt-3",
                          attrs: { to: { name: "pagesnippets" } }
                        },
                        [_vm._v("Back without saving")]
                      ),
                      _c("h3", { staticClass: "mt-3" }, [
                        _vm._v("Default for reference:")
                      ]),
                      _c("div", {
                        staticStyle: {
                          border: "1px solid black",
                          padding: "10px"
                        },
                        domProps: { innerHTML: _vm._s(_vm.snippet.Default) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }